import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <div className="App-header">
        <h1>Cyning AI_</h1>
        <h2>
        A customized <span className="highlight">AI</span> provider for enterprises and governments.
        </h2>
      </div>
      <div className="App-body">
        <h3>What is the plan?</h3>
        <p>
          Cyning AI will be a cutting-edge provider of artificial intelligence
          solutions tailored for both enterprise and government sectors. Our
          innovative AI technology will empower organizations to optimize
          operations, enhance decision-making processes, and drive
          transformative growth. With a focus on advanced machine learning
          algorithms, natural language processing, and data analytics, Cyning AI
          will deliver personalized solutions that meet the unique needs of each
          client. From predictive analytics to intelligent automation, Cyning AI
          will be your trusted partner in harnessing the power of AI for
          sustainable success.
        </p>
        <span className="footer">
          {new Date().getFullYear()} Cyning AI. A sub-entity of{" "}
          <a href="https://onradar.io">onradar</a>, Inc.
        </span>
      </div>
    </div>
  );
}
